import { HStack, Modal, ModalContent, ModalHeader, ModalOverlay, ModalProps, Text } from '@chakra-ui/react'
import type { LineItem, ProductProjection, ShoppingListLineItemDraft } from '@commercetools/platform-sdk'
import { useMemo } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { ShoppingListQueryResult } from 'commercetools/hooks/use-shopping-list'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { CloseButton } from 'components/elements/CloseButton'
import { OrderTemplateCreateModalForm } from 'components/modules/OrderTemplate/OrderTemplateCreateModalForm'

export type OrderTemplateCreateModalProps = Omit<ModalProps, 'children'> & {
  title?: string
  product?: ProductProjection
  lineItems?: ReadonlyArray<LineItem>
  shoppingList?: ShoppingListQueryResult
}

const TAG = 'OrderTemplateCreateModal'

export const OrderTemplateCreateModal = ({
  title,
  shoppingList,
  product,
  lineItems,
  isOpen,
  onClose,
  ...props
}: OrderTemplateCreateModalProps): JSX.Element => {
  const businessUnit = useBusinessUnit()
  const productVariant = getProductVariant(product, businessUnit.erpLegalEntity)
  const newShoppingListLineItems = useMemo<ReadonlyArray<ShoppingListLineItemDraft>>(
    () => (lineItems ? lineItems.map(({ variant }) => ({ ...variant })) : product ? [{ ...productVariant }] : []),
    [lineItems, product, productVariant],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered {...props}>
      <ModalOverlay />
      <ModalContent maxW="36rem" data-testid={TAG}>
        <HStack w="full">
          <ModalHeader>
            <Text>{title}</Text>
            <CloseButton onClick={onClose} />
          </ModalHeader>
        </HStack>

        <OrderTemplateCreateModalForm
          onClose={onClose}
          lineItems={newShoppingListLineItems}
          shoppingList={shoppingList}
        />
      </ModalContent>
    </Modal>
  )
}
