import { ShoppingList, ShoppingListDraft, ShoppingListPagedQueryResponse } from '@commercetools/platform-sdk'

import { UpdateShoppingListValue } from 'commercetools/types'
import { buildURLSearchParams } from 'commercetools/utils/buildURLSearchParams'

import client from '../client'

export const fetchShoppingLists = (customerId?: string, token?: string): Promise<ShoppingListPagedQueryResponse> => {
  const url = '/shopping-lists'
  const params = {
    ...(customerId && { where: `customer(id="${customerId}")` }),
    expand: ['lineItems[*].variant', 'custom.fields.modifiedBy'],
    limit: 100,
  }
  return client.get<ShoppingListPagedQueryResponse>(`${url}?${buildURLSearchParams(params)}`, token)
}

export const fetchBuShoppingLists = (
  businessUnitId?: string,
  token?: string,
): Promise<ShoppingListPagedQueryResponse> => {
  const url = '/shopping-lists'
  const params = {
    ...(businessUnitId && { where: `custom(fields(ctBusinessUnitReference(id="${businessUnitId}")))` }),
    expand: ['lineItems[*].variant', 'custom.fields.modifiedBy'],
    limit: 100,
  }
  return client.get<ShoppingListPagedQueryResponse>(`${url}?${buildURLSearchParams(params)}`, token)
}

export const fetchShoppingListsById = (shoppingListId?: string, token?: string): Promise<ShoppingList> => {
  const url = `/shopping-lists/${shoppingListId}`
  const params = {
    expand: 'lineItems[*].variant',
  }

  return client.get<ShoppingList>(`${url}?${buildURLSearchParams(params)}`, token)
}

export const createShoppingList = (
  shoppingListDraft: ShoppingListDraft | undefined,
  token?: string,
): Promise<ShoppingList> => {
  const url = '/shopping-lists'
  return client.post<ShoppingList, ShoppingListDraft | undefined>(url, shoppingListDraft, token)
}

export const updateShoppingList = (
  id: string,
  { version, actions }: UpdateShoppingListValue,
  token?: string,
): Promise<ShoppingList> =>
  client.post<ShoppingList, UpdateShoppingListValue>(
    `/shopping-lists/${id}`,
    {
      version,
      actions: Array.isArray(actions) ? actions : [actions],
    },
    token,
  )

export const deleteShoppingList = (id: string, version: number, token?: string): Promise<ShoppingList> =>
  client.delete(`/shopping-lists/${id}?version=${version}`, token)
